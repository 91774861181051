<template>
  <div class="product_option" style="display: block">
    <div class="layout_box">
      <div class="layout_header">
        <h3 class="layout_title stand disflex">
          {{ $t('Brand.OptionSelect') }}
          <button
            class="close btn_cim_close"
            type="button"
            @click="$emit('close')"
          />
        </h3>
      </div>
      <div class="option_box inner">
        <div class="cit-name">
          {{ product.cit_name }}
        </div>
        <product-option-group :product="product" @select="handleSelectOption" />

        <div class="option_value" style="display: block">
          <template v-if="selected.length > 0">
            <product-option-item
              v-for="item in selected"
              :key="item.cde_id"
              v-model="item.qty"
              :detail="item"
              :product="product"
              :showCloseButton="item.is_options"
              @close="handleDeleteItem(item)"
              @input="handleChangeCount"
            />
          </template>

          <ul
            v-if="selected[0] && selected[0].order_soldout !== 'y'"
            class="sec_floor"
          >
            <li>
              <P> {{ $t('Order.AllProductAmount') }}</P>
              <h6 class="fontNum">
                ₩
                {{
                  (
                    selected.reduce((acc, item) => {
                      if (item.order_soldout !== 'y') {
                        return acc + item.price * item.qty;
                      }

                      return acc;
                    }, 0) + price.delivery
                  ).numberFormat()
                }}
              </h6>
            </li>
            <li>
              <P> {{ $t('Order.ProductAmount') }} </P>
              <h6>₩ {{ price.total.numberFormat() }}</h6>
            </li>
            <li v-if="product.is_sale === 'y'">
              <p>{{ $t('Basket.DiscountAmount') }}</p>
              <h6 class="fontNum discount_price">
                ₩
                {{
                  selected
                    .reduce((acc, item) => {
                      if (item.order_soldout !== 'y') {
                        return (
                          acc + (product.cit_price - item.price) * item.qty
                        );
                      }

                      return acc;
                    }, 0)
                    .numberFormat()
                }}
              </h6>
            </li>
            <li v-if="delivery">
              <P> {{ $t('Basket.DeliveryFee') }} </P>
              <h6
                v-if="
                  delivery.delivery_type === '조건부 배송비' &&
                  delivery.delivery_type
                "
              >
                <span>{{
                  price.delivery === 0
                    ? $t('Order.FreeShipping')
                    : '₩ ' + price.delivery.numberFormat()
                }}</span>
                <span class="free_del">
                  -
                  {{
                    $t('Order.MoreThan', {
                      Amount: delivery.delivery_type_price.numberFormat(),
                    })
                  }}
                </span>
              </h6>
              <h6
                v-if="
                  delivery.delivery_type === '고정 배송비' &&
                  delivery.delivery_type
                "
              >
                <span> ₩ {{ delivery.delivery_type_fee.numberFormat() }}</span>
              </h6>
              <h6
                v-if="
                  delivery.delivery_type === '무료배송' &&
                  delivery.delivery_type
                "
              >
                <span>{{ $t('Order.FreeShipping') }}</span>
              </h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue-demi';
import ProductOptionGroup from './ProductOptionGroup.vue';
import ProductOptionItem from './ProductOptionItem.vue';
import useToast from '../../../composables/dialogs/useToast';

export default {
  components: { ProductOptionGroup, ProductOptionItem },
  props: ['product', 'delivery'],
  setup({ delivery }, context) {
    const { addMsg } = useToast();

    const state = reactive({
      selected: [],
      optionKey: Date.now(),
      isSoldOut: false,
      price: {
        total: 0,
        delivery: 0,
      },
    });

    const accPrice = () => {
      console.log(state.selected);
      const total = state.selected.reduce((acc, item) => {
        console.log(acc, item);
        if (item.order_soldout !== 'y') {
          state.price.total = parseInt(item.price * item.qty);
          return parseInt(acc + state.price.total);
        }

        return acc;
      }, 0);

      let deliveryPrice = 0;
      switch (delivery ? delivery.delivery_type : '-') {
        case '조건부 배송비':
          if (delivery.delivery_type_price > total) {
            deliveryPrice = Number(delivery.delivery_type_fee);
          }
          break;
        case '고정 배송비':
          deliveryPrice = Number(delivery.delivery_type_fee);
          break;
        default:
          deliveryPrice = 0;
          break;
      }
      state.price.delivery = deliveryPrice;
    };

    watch(
      () => state.selected,
      () => {
        accPrice();
      }
    );

    return {
      ...toRefs(state),
      handleDeleteItem(item) {
        const selected = state.selected.filter((value) => value !== item);
        state.selected = selected;
        context.emit('input', selected);
      },
      handleSelectOption(item) {
        console.log('-> item', item);
        const idx = state.selected.findIndex(
          (val) => val.cde_id === item.cde_id
        );

        if (idx > -1) {
          addMsg(context.root.$t('Order.AlreadySelect'));
        } else {
          const selected = [...state.selected, item];
          state.selected = selected;
          context.emit('input', selected);
        }
      },
      handleReset() {
        state.selected = [];
      },

      handleChangeCount() {
        accPrice();
      },
    };
  },
};
</script>

<style>
.product_option {
  background: #fff;
  bottom: 95px;
}

.layout_title {
  justify-content: space-between;
  padding-right: 14px;
}

.option_box .cit-name {
  padding-bottom: 10px;
}
</style>
